// slices/dataSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import pinataApi from '../Services/PinataApi';
import axios from 'axios'
import api from '../Services/Api';

const pinata_id =  process.env.REACT_APP_PINATA_ID
// initial state
const initialState= {
    data: null,
    loading: false,
    error: null,
  }

// Create an async thunk for fetching data
export const getAllMarketplaceNftsResponse = createAsyncThunk('getAllMarketplaceNfts', async (page) => {
  try {
    // let config = {
    //   method: 'get',
    //   // headers: {
    //   //   'Content-Type': 'application/json',
    //   //   'Accept': 'application/json', 
    //   // },
    //   url: `${process.env.REACT_APP_PINATA_URL}ipfs/${pinata_id}/metadata.json`
    // };

    // console.log('config', config);
    // const response = await axios(config);
    const response = await api.get(`marketplace/all?page=${page}&limit=12`);

    console.log('marketplace', response)
    return response.data.payload.items;
  } catch (error) {
    throw error;
  }
});

// Create a data slice with reducers for success and error cases
const getAllMarketplaceNftsSlice = createSlice({
  name: 'AllMarketplaceNfts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMarketplaceNftsResponse.pending, (state) => {
        state.getAllMarketplaceNftsLoading = true;
        state.getAllMarketplaceNftsError = null;
      })
      .addCase(getAllMarketplaceNftsResponse.fulfilled, (state, action) => {
        state.getAllMarketplaceNftsLoading = false;
        state.getAllMarketplaceNftsData = action.payload;

        console.log('state.data', state.data)
      })
      .addCase(getAllMarketplaceNftsResponse.rejected, (state, action) => {
        state.getAllMarketplaceNftsLoading = false;
        state.getAllMarketplaceNftsError = action.error.message;
      });
  },
});

export default getAllMarketplaceNftsSlice.reducer;
