import { getChainNetworkByChainId } from '../utils/chainList'
import { ethers } from 'ethers'


export const getWeb3PRovider = () => {
  const defaultChainId = process.env.REACT_APP_CHAIN_ID
  const web3configs = getChainNetworkByChainId(defaultChainId)
  //initiate the web3 instance
  const web3 = new ethers.providers.JsonRpcProvider(web3configs.rpcUrls[0], {
    name: web3configs.chainName, chainId: parseInt(web3configs.chainId, 16)
  })
  return web3
}
