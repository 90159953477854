import React, { useState, useEffect } from "react";
import "./Swiper.css";
import bgImage from "../../images/home-animation/card.png";
import image1 from "../../images/home-animation/1.png";
import image2 from "../../images/home-animation/2.png";
import image3 from "../../images/home-animation/3.png";
import image4 from "../../images/home-animation/4.png";

const images = [image1, image2, image3, image4];

const CharacterCard = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3500); // Interval for each image to stay and slide out

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="card-container"
      style={{ backgroundImage: `url(${bgImage})`, backgroundSize: "cover" }}
    >
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          className={`character-layer ${
            index === currentImageIndex ? "active" : ""
          }`}
          alt={`Character ${index + 1}`}
        />
      ))}
    </div>
  );
};

export default CharacterCard;
