import React, { useEffect, useState, useRef } from 'react'
import MintingCard from '../../components/MintingCard/MintingCard'
import './Home.css'
import { Container, Row, Col } from 'reactstrap'
import coverImg from '../../images/cover-img-2.png'
import { Button, Space, Carousel } from 'antd'
import pinkswapLogo from '../../images/logos_rfc/ps.png'
import gempadLogo from '../../images/logos_rfc/gp.png'
import childSupportLogo from '../../images/logos_rfc/cs.png'
import dxsaleLogo from '../../images/logos_rfc/dxs.png'
import hulkGems from '../../images/logos_rfc/hgc.png'
import AOS from 'aos'
import 'aos/dist/aos.css'
import walletIcon from '../../images/Wallet.svg'
import UploadIcon from '../../images/Paper-Upload.svg'
import NFTslider from '../../components/NFTslider/NFTslider'
import Socialicons from '../../components/Socialicons/Socialicons'
import { Link } from "react-router-dom";
import SwiperComp from './SwiperComp'
import { useSelector } from 'react-redux';
import { useAccount } from "wagmi";
function Home() {

  const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };
  const { address } = useAccount()
  const mintSectionRef = useRef(null);

  const scrollToMintSection = () => {
    mintSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const { getAllMarketplaceNftsData, getAllMarketplaceNftsLoading } = useSelector((state) => state.allMarketplaceNfts);
  const [referralAddress, setReferralAddress] = useState('0x0000000000000000000000000000000000000000')
  const [isHaveReferral, setIsHaveReferral] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 700,
      once: false,
      offset: 20
    });
  }, [])

  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.href;

    // Split the URL based on the '?' character
    const urlParts = currentUrl.split('?');

    // Check if there are query parameters
    if (urlParts.length > 1) {
      // Get the part after the '?' which contains the query parameters
      const queryParams = urlParts[1];

      // Split the query parameters based on the '&' character
      const paramPairs = queryParams.split('&');

      // Find the 'ref' parameter
      const refParamPair = paramPairs.find(pair => pair.startsWith('ref='));

      if (refParamPair) {
        // Extract the value of the 'ref' parameter
        const refParam = refParamPair.split('=')[1];

        if (address === refParam) {
          setReferralAddress('0x0000000000000000000000000000000000000000')
          setIsHaveReferral(false)
        } else {
          setReferralAddress(refParam)
          setIsHaveReferral(true)
        }
      }
    }
  }, []);

  return (
    <>
      <section className='home-cover'>
        <Container>
          <Row className='justify-content-center align-items-center main-row'>
            <Col className='hide-on-desktop my-5'>
              <div className='mx-5'>
                <SwiperComp />
              </div>

            </Col>
            <Col className='text-white p-4 pt-0' md={7}>
              <h1>
                <div data-aos="fade-up">Dive into NFT Bliss with </div>
                <div className='hilighted' data-aos="fade-up" data-aos-delay="200">RugfreeCoins NFTs</div>
              </h1>
              <p data-aos="fade-up" data-aos-delay="500">Unveiling the cutting-edge NFT minting and gating platform, designed for NFT holders to seamlessly enter the vibrant Rugfreecoins community. Enjoy boundless perks and privileges across all utilities, ushering in the next era of NFT innovation.</p>
              <div className='btn-wrapper'>
                <Space>
                  <Link className="navbar-brand desktop-logo" to="https://www.rugfreecoins.com/" target="_blank">
                    <Button data-aos="fade-in" data-aos-delay="700" type='primary' size='large'>Explore More</Button>
                  </Link>
                  <Button data-aos="fade-in" data-aos-delay="900" size='large' className='transparent-btn' onClick={scrollToMintSection}>Mint NFT</Button>
                </Space>
              </div>
            </Col>
            <Col className='hide-on-mobile' md="5">
              {/* <img data-aos="fade-up" data-aos-delay="300" className='img-fluid' src={coverImg} alt="" /> */}
              <div className='mx-5'>
                <SwiperComp />
              </div>

            </Col>
          </Row>
          {/* <SwiperComp /> */}
          <Row className='d-none d-md-flex justify-content-center logo-row pb-5' data-aos="fade-in" data-aos-delay="700">
            <Col sm={12} md={12} className='d-flex flex-column flex-md-row justify-content-between align-items-center'>
              <div className='patners-card'>
                <div className='d-flex flex-row'>
                  <img src={pinkswapLogo} />
                </div>
              </div>
              <div className='patners-card'>
                <div className='d-flex flex-row'>
                  <img src={gempadLogo} />
                </div>
              </div>
              <div className='patners-card'>
                <div className='d-flex flex-row'>
                  <img src={hulkGems} />
                </div>
              </div>
              <div className='patners-card'>
                <div className='d-flex flex-row'>
                  <img src={dxsaleLogo} />
                </div>
              </div>
              <div className='patners-card'>
                <div className='d-flex flex-row'>
                  <img src={childSupportLogo} />
                </div>
              </div>

            </Col>
          </Row>
          <Row className='d-flex d-md-none logo-mobile-row'>
            <Col sm={12} md={12} className=''>
              <Carousel autoplay>
                <div className='patners-card'>
                  <div className='d-flex justify-content-center'>
                    <img src={pinkswapLogo} height={'50px'} />
                  </div>
                </div>
                <div className='patners-card'>
                  <div className='d-flex justify-content-center'>
                    <img src={gempadLogo} height={'50px'} />
                  </div>
                </div>
                <div className='patners-card'>
                  <div className='d-flex justify-content-center'>
                    <img src={hulkGems} height={'50px'} />
                  </div>
                </div>
                <div className='patners-card'>
                  <div className='d-flex justify-content-center'>
                    <img src={dxsaleLogo} height={'50px'} />
                  </div>
                </div>
                <div className='patners-card'>
                  <div className='d-flex justify-content-center'>
                    <img src={childSupportLogo} height={'50px'} />
                  </div>
                </div>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section>
      <section ref={mintSectionRef} className='mint-section d-flex flex-row justify-content-center align-items-center'>
        <Container>
          <Row>
            <Col className='' md={12}>
              <h2 className='section-title text-center' data-aos="fade-up">Mint Your NFT</h2>
            </Col>
          </Row>
          <Row className='gx-0 gx-lg-5 justify-content-center align-items-center'>
            <Col className='text-white p-4 pb-0 p-md-0' md={4}>
              <div className="benefits-section content-paragraph-type-1 ">
                <div className="benefit-item" data-aos="fade-up" data-aos-delay="100">
                  {/* <p>Benefit of early access to future collections and exclusive access to a range of perks.</p> */}
                  <p>Unlock whitelist wallet access for the private sale/presale with RFC token NFT ownership.</p>
                </div>
                <div className="benefit-item" data-aos="fade-up" data-aos-delay="200">
                  {/* <p>Receive invitations to exclusive events of a passionate community.</p> */}
                  <p>Stake NFTs and earn free early tokens before the launch.</p>
                </div>
                <div className="benefit-item" data-aos="fade-up" data-aos-delay="300">
                  {/* <p>Opportunity to participate in community-driven decision-making processes.</p> */}
                  <p>Enjoy access to RFC tools and games.</p>
                </div>
              </div>
            </Col>
            <Col md={4} className='order-first order-md-2 p-4 pt-0 pb-0'>
              <MintingCard referralAddress={referralAddress} isHaveReferral={isHaveReferral} />
            </Col>
            <Col md={4} className='order-3 order-md-3 p-4 pt-0 p-md-0'>
              <div className='benefits-section d-flex flex-column flex-md-row flex-wrap justify-content-between content-paragraph-type-1 '>
                <div className="benefit-item" data-aos="fade-up" data-aos-delay="400">
                  {/* <p>By owning a Rugfreecoins NFT, Become a custodian of one of these extraordinary digital treasures.</p> */}
                  <p>Participate in community-driven decision-making processes.</p>
                </div>
                <div className="benefit-item mt-4" data-aos="fade-up" data-aos-delay="500">
                  {/* <p>Support the organisations dedicated to eagle conservation and habitat restoration initiatives.</p> */}
                  <p>Avail a 15% standard discount for audits, smart contract developments, and Dapp developments.</p>
                </div>
                {/* <div className="benefit-item" data-aos="fade-up" data-aos-delay="600">
                  <p>become a part of a passionate community that appreciates the natural world and the artistry it inspires.</p>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className='mint-slider'>
        <Container>
          <Row className='py-3 mt-5'>
            <Col>
              <h2 data-aos="fade-in" data-aos-delay="100" className='section-title text-center'>Mint And HODL Your NFTs</h2>
            </Col>
          </Row>
        </Container>
      </section>

      <section className='steps-box-section' data-aos="fade-up">
        <Container>
          <Row className='title-row'>
            <Col md={12}>
              <h2 data-aos="fade-in" data-aos-delay="100" className='section-title text-center mb-5'>
                {/* Mint And Sell Your NFTs */}
              </h2>
            </Col>
          </Row>
          <Row className='items-row'>
            <Col md={4}>
              <div data-aos="fade-in" data-aos-delay="200" className='item text-white text-center'>
                <img src={walletIcon} />
                <h4 className="title">
                  Set up your wallet
                </h4>
                <div>
                  <p className="info">
                    Opt for a wallet compatible with our minting platform, such as MetaMask, and Trust wallet. Download the extension or app, create an account, and safeguard your recovery seed for secure and streamlined access.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div data-aos="fade-in" data-aos-delay="300" className='item text-white text-center'>
                <img src={UploadIcon} />
                <h4 className="title">
                  Mint Your NFT
                </h4>
                <div>
                  <p className="info">
                    Unlock the thrill of NFT acquisition on our platform by paying just 1 BNB! Purchase your exclusive NFT and revel in the excitement as you receive a randomly selected digital masterpiece from our unique collection.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div data-aos="fade-in" data-aos-delay="400" className='item text-white text-center'>
                <img src={walletIcon} />
                <h4 className="title">
                  Hold the NFT for life!
                </h4>
                <div>
                  <p className="info">
                    Hold onto your NFT for life and enjoy exclusive perks! You gain access to benefits like early releases, RFC token whitelisting, access for tools and potential airdrops. Embrace the long-term commitment for a growing collection value and an expanding set of privileges. Hold the NFT, grab the perks, and be a key part of our vibrant community!
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className='cards-slider'>
        <Container>
          <Row className='py-3'>
            <Col>
              <h2 className='section-title text-center'>Trending NFTs</h2>
            </Col>
          </Row>
          <Row>
            <Col className='pb-3'>
              {/* <TrendingNFTItem /> */}
              <NFTslider />
            </Col>
          </Row>
          <Row className='mt-2 text-center'>
            <Col>
              <Link to={'/listing'} >
                <Button shape='round' size='large' style={{ width: '150px' }}>View All</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="cta-section" data-aos="fade-up">
        <Container>
          <Row className='justify-content-center'>
            <Col xs={10} md={10} className='text-center'>
              <h2 data-aos="fade-up" data-aos-delay="100" className='section-title text-white text-uppercase text-center'>
                Let's get in touch
              </h2>
              <div data-aos="fade-up" data-aos-delay="200" className='text-center text-white'>
                <p className='mx-auto'>
                  We would love to hear from you! If you have any questions, comments or feedback about our products or services, please don’t hesitate to contact us.
                </p>
              </div>
              <div data-aos="fade-in" data-aos-delay="500">
                <Socialicons />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Home