import {ConfigProvider, theme, Button, notification} from 'antd'
import './App.css';
import LayoutView from './LayoutView';
import { BrowserRouter as Router } from "react-router-dom";


function App() {
  const { darkAlgorithm } = theme;

  notification.config({
    className: 'andt-notification-customization',
  })

  return (
    <ConfigProvider 
    theme={{
      algorithm: [darkAlgorithm],
      token: {
        colorPrimary: "#505cff",
        // colorBorderSecondary: "#5c63c6"
      },
    }}>
      <Router>
        <LayoutView />
      </Router>
    </ConfigProvider>
  );
}

export default App;
