import React, { useEffect, useState } from "react";
import { Button, Card, Table, Typography, notification, Spin } from "antd";
import { Col, Row } from "reactstrap";
import { useAccount } from "wagmi";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";
import api from "../../Services/Api";
import { readContract } from "@wagmi/core";
import { nftConfig } from '../../blockchain/nft.config'
import WalletConnectButton from '../../components/WalletConnectButton/WalletConnectButton'

function Referral() {
  const { Title, Text } = Typography;
  const { address } = useAccount();
  const [referralPercentageLoading, setReferralPercentageLoading] =
    useState(false);
  const [isCoppied, setIsCoppied] = useState(false);
  const [userRefDetailLoading, setUserRefDetailLoading] = useState(false)
  const [referralData, setReferralData] = useState([]);
  const [totalReferral, setTotalReferral] = useState(0);
  const [isRefPercentageLoading, setIsRefPercentageLoading] = useState(false);
  const [refPercentage, setRefPercentage] = useState(0);
  const [isSpecialUser, setIsSpecialUser] = useState(false);

  useEffect(() => {
    getUserReferral()
    setReferralPercentageLoading(true);
    getIsSpecial();
    setTimeout(() => {
      setReferralPercentageLoading(false);
    }, 2000);
  }, [address]);

  const handleCopyButton = () => {
    navigator.clipboard.writeText(window.location.origin + "?ref=" + address);
    notification["success"]({
      message: "The NFTs are successfully minted",
      role: "status",
    });
    setIsCoppied(true);
  };

  const getUserReferral = async () => {
    try {
      setUserRefDetailLoading(true)
      const response = await api.get(`referal-income/get-by-user/${address}?limit=100&page=1`);
      console.log('response', response);
      setReferralData(response.data.payload.items)
      setUserRefDetailLoading(false)
    } catch (error) {
      console.log('error', error);
      setUserRefDetailLoading(false)
    }
  };

  const getIsSpecial = async () => {
    setIsRefPercentageLoading(true)
    try {
      const isSpecialResopnse = await readContract({
        address: nftConfig.mintingContractAddress,
        abi: nftConfig.MintingContractABI,
        functionName: "isSpecial",
        args: [address]
      })

      if (isSpecialResopnse) {
        setIsSpecialUser(true)
        const referalPercentageResponse = await readContract({
          address: nftConfig.mintingContractAddress,
          abi: nftConfig.MintingContractABI,
          functionName: "specialReferal"
        });
        setRefPercentage(referalPercentageResponse.toString());
        
      } else {
        setIsSpecialUser(false)
        getReferralPercentage()
      }
      setIsRefPercentageLoading(false)
    
    } catch (error) {
      console.warn('Error getting isSpecial: ', error)
      // setTotalSupply(0);
      setIsRefPercentageLoading(false)
    }
  }

  const getReferralPercentage = async () => {

    setIsRefPercentageLoading(true)
    try {

      const refPercentageResponse = await readContract({
        address: nftConfig.mintingContractAddress,
        abi: nftConfig.MintingContractABI,
        functionName: "referalPercentage"
      })

      setRefPercentage(refPercentageResponse)

      setIsRefPercentageLoading(false)

    } catch (e) {
      console.log("Error getting referral percentage: ".e)
      setIsRefPercentageLoading(false)
    }

  }
  const columns = [
    {
      title: "Wallet",
      dataIndex: "fromUser",
      key: "fromUser",
      render: (text, record) => (
        <a
          href={`https://bscscan.com/address/${record.fromUser}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "#7d7ff1", fontWeight: "semibold" }}
        >
          {record.fromUser}
        </a>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  return (
    <div
      className="container mt-5"
      style={{ padding: "60px 0px", backgroundColor: "" }}>
      {
        address ? (
          <>
            <div className="mx-2">
              {/* <Card className="glassmorphism-card text-center"> */}
                <Row>
                  <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
                    <Card className="glassmorphism-card h-100 text-center">
                      <Title level={4}>Your Referral Link</Title>
                      <Text>
                        {window.location.origin}?ref={address}
                      </Text>

                      <div className="mt-3">
                        <Button
                          type="primary"
                          icon={isCoppied ? <CheckOutlined /> : <CopyOutlined />}
                          onClick={handleCopyButton}
                        >
                          Copy
                        </Button>
                      </div>
                    </Card>
                  </Col>

                  <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
                    <Card className="glassmorphism-card mt-3 mt-lg-0 h-100 text-center">
                      <Title level={4}>Referral Percentage</Title>
                      <Title level={5}>
                        {isRefPercentageLoading ? (
                          <Spin size="small" />
                        ) : (
                          `${refPercentage} %`
                        )}
                      </Title>
                    </Card>
                  </Col>
                </Row>
              {/* </Card> */}
            </div>
            <div className="container mt-5">
              <Card className=" text-center text-center">
                <Title level={4}>Your Referrals</Title>

                {
                  referralData.length > 0 ? (
                    <div className="mt-4">
                      <Table
                        pagination={false}
                        dataSource={referralData}
                        columns={columns}
                        rowKey={(record) => record.wallet}
                        className="table-responsive"
                        loading={userRefDetailLoading}
                      />
                    </div>
                  ) : (
                    <span>You don't have any referrals yet</span>
                  )
                }

              </Card>
            </div>
          </>
        ) : <div className="d-flex justify-content-center"><WalletConnectButton /></div>
      }

    </div>
  );
}

export default Referral;
