import { configureStore } from '@reduxjs/toolkit'
import getAllMarketplaceNftsReducer from './getAllMarketplaceNftsPinataSlice'
import getNftsByOwnerReducer from './getAllNftsByOwner'
import getNftsForSaleByOwnerReducer from './getNftsForSaleByOwner'


export const store =  configureStore({
  reducer: {
    allMarketplaceNfts: getAllMarketplaceNftsReducer,
    getNftsByOwner: getNftsByOwnerReducer,
    getNftsForSaleByOwner: getNftsForSaleByOwnerReducer,
  },
})