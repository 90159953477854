import React, { useEffect } from 'react';
import { Space, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMarketplaceNftsResponse } from '../../Redux/getAllMarketplaceNftsPinataSlice';
import TrendingNFTItem from '../../components/TrendingNFTItem/TrendingNFTItem';

function NFTslider() {
  const dispatch = useDispatch();
  const { getAllMarketplaceNftsData, getAllMarketplaceNftsLoading } = useSelector(
    (state) => state.allMarketplaceNfts
  );

  useEffect(() => {
    dispatch(getAllMarketplaceNftsResponse(1));
  }, []);

  return (
    <div>
      {getAllMarketplaceNftsLoading ? (
        <Spin />
      ) : (
        <Space className='d-flex flex-row flex-wrap justify-content-center' size={20}>
          {/* Display only the first four items from getAllMarketplaceNftsData */}
          {getAllMarketplaceNftsData?.slice(0, 4).map((item, index) => (
            <div key={index}>
              <TrendingNFTItem
                type={'home-card'}
                nftDataId={item.nftId}
                cardWidth='260px'
                sourcePage={'homePage'}
              />
            </div>
          ))}
        </Space>
      )}
    </div>
  );
}

export default NFTslider;
