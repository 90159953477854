import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Home from './Home/Home'
import Minting from './Minting/Minting'
import Listing from './Listing/Listing'
import InventoryPage from './Inventory/InventoryPage'
import Referral from './Referral/Referral'
import Admin from './Admin/Admin'

function Pages() {
  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/minting" exact element={<Minting />} />
      <Route path="/listing" exact element={<Listing />} />
      <Route path="/inventory" exact element={<InventoryPage />} />
      <Route path="/referral" exact element={<Referral />} />
      <Route path="/admin" exact element={<Admin />} />
    </Routes>
  )
}

export default Pages