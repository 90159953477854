import axios from 'axios'


const pinataApi = axios.create({
  baseURL: process.env.REACT_APP_PINATA_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json', 
  }
})

export default pinataApi

export const AXIOS = axios