import React, { useEffect, useState } from 'react'
import { Space, Tabs, Badge, Spin, Pagination } from 'antd'
import TabPane from 'antd/es/tabs/TabPane'
import MintingCard from '../../components/MintingCard/MintingCard'
import TrendingNFTItem from '../../components/TrendingNFTItem/TrendingNFTItem'
import { readContract } from '@wagmi/core'
import { nftConfig } from '../../blockchain/nft.config'
import { useAccount } from 'wagmi'
import WalletConnectButton from '../../components/WalletConnectButton/WalletConnectButton'
import { getAllNftsByOwnerResponse } from '../../Redux/getAllNftsByOwner'
import { getNftsForSaleByOwnerResponse } from '../../Redux/getNftsForSaleByOwner'
import { useDispatch, useSelector } from 'react-redux';

function InventoryPage() {
    

    const { getNftsByOwnerData, getNftsByOwnerDataLoading, getNftsByOwnerDataError } = useSelector(state => state.getNftsByOwner)
    const { nftsForSaleByOwnerData, nftsForSaleByOwnerDataLoading, nftsForSaleByOwnerDataError } = useSelector(state => state.getNftsForSaleByOwner)
    const dispatch = useDispatch()

    console.log('nftsForSaleByOwnerData', nftsForSaleByOwnerData);
    const { address, isConnected } = useAccount()

    // const [tabTitle, setTabTitle] = useState('My NFTs')
    const [myNftsForSaleTabTitle, setMyNftsForSaleTabTitle] = useState('My NFTs for sale')

    const [allNftsOwnerPageNumber, setAllNftsOwnerPageNumber] = useState(1);
    const [allNftsForSalePageNumber, setAllNftsForSalePageNumber] = useState(1)

    const [myNftsTabTitle, setMyNftsTabTitle] = useState()

    // get data from child cNFt component
    const [childComponentAction, setChildComponentAction] = useState('')

    // check NFTs by wallet address upon isConnected or address vars changed
    useEffect(() => {
        if (isConnected) {
            dispatch(getAllNftsByOwnerResponse({ walletAddress: address, pageNumber: allNftsOwnerPageNumber }))
            dispatch(getNftsForSaleByOwnerResponse({ walletAddress: address, pageNumber: allNftsForSalePageNumber }))
        }
    }, [isConnected, address, allNftsOwnerPageNumber])

    // handle pagination for NFT lists
    const handleAllNFtsByOwnerPageChange = (page) => {
        setAllNftsOwnerPageNumber(page);
    };

    const handleAllNFtsForSaleByOwnerPageChange = (page) => {
        setAllNftsForSalePageNumber(page);
    };


    // get data from child component to check whether a NFT is listed for sale
    const childDataReciverHandler = (data)  => {
        console.log('ggggg 2', data);
        if (data === 'reaload') {
            dispatch(getAllNftsByOwnerResponse({ walletAddress: address, pageNumber: allNftsOwnerPageNumber }))
            dispatch(getNftsForSaleByOwnerResponse({ walletAddress: address, pageNumber: allNftsForSalePageNumber }))
        }

    }

    //  update NFT tab data when a action happened in NFT item
    useEffect(() => {
        dispatch(getAllNftsByOwnerResponse({ walletAddress: address, pageNumber: allNftsOwnerPageNumber }))
        dispatch(getNftsForSaleByOwnerResponse({ walletAddress: address, pageNumber: allNftsForSalePageNumber }))
    }, [childComponentAction])

    // generate Tab titles
    const generateMyNftsTabTitle = () => {
        const tabTitle = <>My NFTs <Badge color='#4751dc' count={getNftsByOwnerData?.payload?.meta.totalItems} showZero/></>
        setMyNftsTabTitle(tabTitle)
    }

    const generateMyNftsForSaleTabTitle = () => {
        const tabTitle = <>My NFTs for sale <Badge color='#4751dc' count={nftsForSaleByOwnerData?.payload?.meta.totalItems} showZero/></>
        setMyNftsForSaleTabTitle(tabTitle)
    }

    useEffect(() => {
        generateMyNftsTabTitle()
    }, [getNftsByOwnerData])

    useEffect(() => {
        generateMyNftsForSaleTabTitle()
    }, [nftsForSaleByOwnerData])

    return (
        <>
            <div className="container mt-5" style={{ padding: '60px 0px', backgroundColor: '' }}>
                <div className="row page-cover text-center mb-3">
                    <h1 className='text-white page-title' style={{ fontWeight: 600 }}>Your Collection</h1>
                </div>
            </div>
            <div className="container mb-5">
                <div className="row">
                    <Tabs size='large'>
                        <Tabs.TabPane tab={myNftsTabTitle} key='tab-1'>

                            {isConnected ? (

                                <div className="d-flex flex-column">
                                    <div className="all-nft-items d-flex flex-row flex-wrap justify-content-start gap-3">

                                        {getNftsByOwnerDataLoading ? (
                                            <Spin className='w-100' size={'large'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                                        ) :
                                            getNftsByOwnerData?.payload?.items.length > 0 ?
                                                getNftsByOwnerData?.payload?.items.map((item, index) => (

                                                    <TrendingNFTItem type={'sell-card'} key={index} nftDataId={item.nftIds} cardWidth='260px' childDataReciver={childDataReciverHandler} sourcePage={'inventoryPageMyNfts'} />

                                                )
                                                ) : (
                                                    <span>Sorry!You Have no NFTs in your wallet.</span>
                                                )
                                        }

                                    </div>
                                    <div className="pagination-area d-flex justify-content-center mt-5 w-100">
                                        <Pagination
                                            current={allNftsOwnerPageNumber}
                                            pageSize={12}
                                            total={getNftsByOwnerData?.payload?.meta.totalItems || 0}
                                            onChange={handleAllNFtsByOwnerPageChange}
                                            style={{ margin: 'auto' }}
                                        />
                                    </div>

                                </div>

                            ) : (
                                <div className='w-100 text-center mt-4' style={{ minHeight: '250px' }}>
                                    <h3 className='text-white mb-3'>Connect Your Wallet</h3>
                                    <WalletConnectButton />
                                </div>
                            )}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={myNftsForSaleTabTitle} key='tab-2'>

                            {isConnected ? (
                                <div className='d-flex flex-column flex-wrap justify-content-center' style={{ minHeight: '500px' }}>
                                    <div className='d-flex flex-row flex-wrap justify-content-start w-100 gap-3'>
                                        {nftsForSaleByOwnerDataLoading ? (
                                            <Spin className='w-100' size={'large'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                                        ) :
                                            nftsForSaleByOwnerData?.payload.items.map((item, index) => (
                                                <TrendingNFTItem key={index} type={'buy-card'} nftDataId={item.nftId} cardWidth='260px' sourcePage={'inventoryPageSellingItems'} />
                                            ))}
                                    </div>
                                    <div className="pagination-area d-flex justify-content-center mt-5 w-100">
                                        <Pagination
                                            current={allNftsForSalePageNumber}
                                            pageSize={12}
                                            total={nftsForSaleByOwnerData?.payload?.meta.totalItems || 0}
                                            onChange={handleAllNFtsForSaleByOwnerPageChange}
                                            style={{ margin: 'auto' }}
                                        />
                                    </div>

                                </div>
                            ) : (
                                <div className='w-100 text-center mt-4' style={{ minHeight: '250px' }}>
                                    <h3 className='text-white mb-3'>Connect Your Wallet</h3>
                                    <WalletConnectButton />
                                </div>
                            )}

                        </Tabs.TabPane>
                    </Tabs>

                </div>
            </div>
        </>
    )
}

export default InventoryPage