import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { store } from './Redux/store';
import { Provider } from 'react-redux';
import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultWallets, RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { bsc } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';

const { chains, provider } = configureChains([bsc], [publicProvider()]);

// const { connect } = createClient({
//   autoConnect: true,
//   connectors: [
//     ...getDefaultWallets({
//       appName: 'RFC-NFT',
//       projectId: 'e8761e51054bdb979d1bc2a7d86f5b83',
//       chains
//     }).connectors
//   ],
//   provider
// })
const { connectors } = getDefaultWallets({
  appName: 'RFC-NFT',
  projectId: 'e8761e51054bdb979d1bc2a7d86f5b83',
  chains
});

const wagmiClient = createClient({
	autoConnect: true,
	connectors,
	provider
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} theme={darkTheme()} initialChain={process.env.REACT_APP_CHAIN_ID || null}>
        <Provider store={store}>
          <App />
        </Provider>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();