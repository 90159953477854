import React, { useEffect, useState } from "react";
import { nftConfig } from '../../blockchain/nft.config'
import { getMintingContractABIFunctions } from '../../Services/admin.service'
import ABIFunctions from './ABIFunctions'
import { useAccount } from "wagmi";

const Admin = () => {

    const adminAccounts = process.env.REACT_APP_ADMINS_ACCOUNT;
    const [isAdmin, setIsAdmin] = useState(false);
    const { address } = useAccount();
    const [mintingReadFunctions, setMintingReadFunctions] = useState([]);
    const [mintingWriteFunctions, setMintingWriteFunctions] = useState([]);

    const fetchMintingContractFunctions = () => {
        const response = getMintingContractABIFunctions()
        setMintingReadFunctions(response.readFunctionsList)
        setMintingWriteFunctions(response.writeFunctionList)
    }

    useEffect(() => {
        fetchMintingContractFunctions()
    }, [])

    useEffect(() => {
        let admins = adminAccounts ? adminAccounts.split(',') : null;
        if (address && admins?.length > 0) {
            const result = admins.find(
                (item) => item.toLowerCase() === address.toLowerCase()
            );
            if (result) {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
        } else {
            setIsAdmin(false);
        }
    }, [address, adminAccounts]);

    return (
        <div
            className="container mt-5"
            style={{ padding: "60px 0px", backgroundColor: "" }}
        >
            {
                isAdmin ? (
                    <ABIFunctions
                        contractABI={JSON.parse(nftConfig.MintingContractABI)}
                        contractAddress={process.env.REACT_APP_MINTING_CONTRACT_ADDRESS}
                        readFunctions={mintingReadFunctions}
                        writeFunctions={mintingWriteFunctions}
                        tabName={'Token'}
                    />
                ) : <h1 className="text-center mt-5">You are not authorized to access this page</h1>
            }

        </div>
    )
}

export default Admin