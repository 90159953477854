import React, { useEffect, useState } from 'react'
import './Listing.css'
import TrendingNFTItem from '../../components/TrendingNFTItem/TrendingNFTItem'
import { readContract } from '@wagmi/core'
import { nftConfig } from '../../blockchain/nft.config'
import { Space, Spin, Pagination } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { getAllMarketplaceNftsResponse } from '../../Redux/getAllMarketplaceNftsPinataSlice'
import axios from 'axios'

function Listing() {

  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const { getAllMarketplaceNftsData, getAllMarketplaceNftsLoading, getAllMarketplaceNftsError } = useSelector((state) => state.allMarketplaceNfts);

  useEffect(() => {
    dispatch(getAllMarketplaceNftsResponse(currentPage))
  }, [])

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  console.log('All NFt items: ', getAllMarketplaceNftsData)

  // const childDataReciverHandler = (data) => {
  //   setChildComponentAction(data)

  //   console.log('===child component performed some action===')
  //   console.log("childComponentAction: ", childComponentAction)
  // }


  return (
    <>
      <div className="container mt-5" style={{ padding: '60px 0px', backgroundColor: '' }}>
        <div className="row page-cover text-center mb-3 justify-content-center">
          <div className="col-md-10">
            <h1 className='text-white page-title' style={{ fontWeight: 600 }}>Marketplace </h1>
            <div>
              {/* <p className='text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tincidunt dolor at ipsum tempor, non consectetur diam viverra. Fusce imperdiet sem at massa tempor dignissim. Pellentesque pretium vehicula ante in egestas. </p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container mt- mb-5">
        <div className="row">
          {getAllMarketplaceNftsLoading ?
            <Spin /> : (
              <Space className='d-flex flex-row flex-wrap justify-content-center' size={20}>
                {getAllMarketplaceNftsData?.map((item, index) =>
                  <TrendingNFTItem key={index} type={'buy-card'} nftDataId={item.nftId} cardWidth='260px' sourcePage={'listingPage'} />
                )}
              </Space>
            )}
        </div>
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <div className="pagination mt-5">
              <Pagination
                current={currentPage}
                pageSize={10}
                total={getAllMarketplaceNftsData?.payload?.meta.totalItems || 0}
                onChange={handlePageChange}
                style={{ margin: 'auto' }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Listing