import { nftConfig } from '../blockchain/nft.config'

export const getMintingContractABIFunctions = () => {
  let readFunctionsList = []
  let writeFunctionList = []
  const mintingContractABI = JSON.parse(nftConfig.MintingContractABI)
  readFunctionsList = mintingContractABI.filter(item => item.type === 'function' && item.stateMutability === 'view')
  writeFunctionList = mintingContractABI.filter(item => item.type === 'function' && item.stateMutability !== 'view')
  return {
    readFunctionsList,
    writeFunctionList

  }
}


