import {
  Card,
  Row,
  Col,
  Collapse,
  Button,
  Input,
  Spin,
  notification,
  Select,
} from "antd";

import { useEffect, useState } from "react";
import { getWeb3PRovider } from "../../Services/util.service";
import { ethers } from "ethers";
import parse from "html-react-parser";
import { useAccount, useSigner } from "wagmi";
import { nftConfig } from "../../blockchain/nft.config";
import { writeContract } from "@wagmi/core";

function WriteFunctions(props) {
  const { Option } = Select;

  const { writeFunctions, contractABI, contractAddress } = props;

  const { Panel } = Collapse;

  const [inputArguments, setInputArguments] = useState([]);
  const [inputBoolArguments, setInputBoolArguments] = useState(true);
  const [isBoolFunction, setIsBoolFunction] = useState(false);
  const [isFunctionCallLoading, setIsFunctionCallLoading] = useState([]);
  const [resultView, setResultView] = useState([]);
  const [spacialUserStatus, setSpacialUserStatus] = useState(true);
  const [specialUserWalletAddress, setSpecialUserWalletAddress] = useState("");

  const { address: account } = useAccount();
  const { data: signer } = useSigner();

  const handleQuery = (abiData) => async () => {
    if (!account) {
      notification["error"]({
        message: "Authentication Error",
        description: "Please connect your wallet to proceed",
      });
    }

    //decode input values
    const functionName = abiData.name;
    const argumentLength = abiData.inputs.length;
    const argumentArray = [];
    for (let i = 0; i < argumentLength; i++) {
      const key = functionName + i;
      const filteredInput = inputArguments[key];
      argumentArray.push(filteredInput);
    }
    setIsFunctionCallLoading((prev) => ({ ...prev, [functionName]: true }));

    try {
      //create web3 instance
      const provider = getWeb3PRovider();
      const contractInstance = new ethers.Contract(
        contractAddress,
        contractABI,
        provider
      );
      const contractInstanceWithSigner = contractInstance.connect(signer);
      // console.log('functionName', functionName);
      // console.log('argumentArray', argumentArray);
      // console.log('inputBoolArguments', inputBoolArguments);

      let writeReceipt;

      if (isBoolFunction) {
        writeReceipt = await contractInstanceWithSigner[functionName](
          ...argumentArray
        );
      } else {
        writeReceipt = await contractInstanceWithSigner[functionName](
          inputBoolArguments
        );
      }

      const result = await writeReceipt.wait();
      console.log("TX SUCCESS ", result);

      const htmlResult = `<a 
                href=${process.env.REACT_APP_BLOCK_EXPLORER}/tx/${result?.transactionHash}
                target="_blank"
                className="view-your-tx"
            >
            View your transaction<a>`;
      setResultView((prev) => ({ ...prev, [functionName]: htmlResult }));
      setIsFunctionCallLoading((prev) => ({ ...prev, [functionName]: false }));
      notification["success"]({
        message: "Success",
        description: "Transaction has been completed",
      });
    } catch (error) {
      let errorMessage =
        "Something went wrong while trying to execute the transaction";
      if (error && error.message) {
        errorMessage = error.message;
      }
      if (error && error.reason && error.reason !== "") {
        errorMessage = error.reason;
      }
      console.log("WRITE ERROR ", error);
      setResultView((prev) => ({ ...prev, [functionName]: "error" }));
      setIsFunctionCallLoading((prev) => ({ ...prev, [functionName]: false }));
      notification["error"]({
        message: "Execution Error",
        description: errorMessage,
      });
    }
  };

  const handleChange = (e) => {
    if (e.target) {
      const { value, id } = e.target;
      setInputArguments((prev) => ({ ...prev, [id]: value }));
      setIsBoolFunction(true);
    } else {
      setInputBoolArguments(e);
      setIsBoolFunction(false);
    }
  };

  const buildInputRows = (itemName, inputArgs) => {
    const inputArray = [];
    for (let i = 0; i < inputArgs.length; i++) {
      inputArray.push(
        <div className="mt-1">
          <span key={i}>{inputArgs[i].name}</span>
          {inputArgs[i].type === "bool" ? (
            <Select
              style={{
                width: "100%",
              }}
              placeholder="select true or false"
              defaultValue={true}
              onChange={handleChange}
              optionLabelProp="label"
            >
              <Option value={true} label="true">
                true
              </Option>
              <Option value={false} label="false">
                false
              </Option>
            </Select>
          ) : (
            <Input
              placeholder={inputArgs[i].type}
              onChange={handleChange}
              key={itemName + i}
              id={itemName + i}
            />
          )}
        </div>
      );
    }

    return inputArray;
  };

  const handleSpecialUserChange = (value) => {
    setSpacialUserStatus(value);
  };

  const handleSpecialUserWalletChange = (e) => {
    if (e) {
      setSpecialUserWalletAddress(e.target.value);
    }
  };

  const handleSpecialUserAdd = async () => {
    setIsFunctionCallLoading((prev) => ({
      ...prev,
      ["addSpecialReferals"]: true,
    }));

    try {
      const { hash, wait } = await writeContract({
        address: nftConfig.mintingContractAddress,
        abi: nftConfig.MintingContractABI,
        functionName: "addSpecialReferals",
        args: [specialUserWalletAddress, spacialUserStatus],
      });
      const result = await wait();
      console.info("Success: ", result);
      const htmlResult = `<a 
                href=${process.env.REACT_APP_BLOCK_EXPLORER}/tx/${result?.transactionHash}
                target="_blank"
                className="view-your-tx"
            >
            View your transaction<a>`;
      setResultView((prev) => ({
        ...prev,
        ["addSpecialReferals"]: htmlResult,
      }));
      setIsFunctionCallLoading((prev) => ({
        ...prev,
        ["addSpecialReferals"]: false,
      }));
      notification["success"]({
        message: "Add SpecialReferals",
        // description: 'Y',
        role: "status",
      });
      setIsFunctionCallLoading((prev) => ({
        ...prev,
        ["addSpecialReferals"]: false,
      }));
    } catch (e) {
      console.warn("Error in Add SpecialReferals: ", e);
      setIsFunctionCallLoading((prev) => ({
        ...prev,
        ["addSpecialReferals"]: false,
      }));
    }
  };

  return (
    <div>
      {writeFunctions ? (
        <Row>
          <Col span={24}>
            {writeFunctions.map((item, index) => (
              <Card className="mt-3">
                <Collapse defaultActiveKey={["1"]}>
                  {item.name === "addSpecialReferals" ? (
                    <Panel header={item.name} key="3">
                      <div className="mt-1">{item.inputs[0].name}</div>
                      <Input
                        onChange={handleSpecialUserWalletChange}
                        placeholder={item.inputs[0].type}
                      />

                      <div className="mt-1">{item.inputs[1].name}</div>
                      <Select
                        style={{
                          width: "100%",
                        }}
                        placeholder="select true or false"
                        defaultValue={true}
                        onChange={handleSpecialUserChange}
                        optionLabelProp="label"
                      >
                        <Option value={true} label="true">
                          true
                        </Option>
                        <Option value={false} label="false">
                          false
                        </Option>
                      </Select>
                      <Button
                        size="small"
                        loading={isFunctionCallLoading[item.name]}
                        style={{ padding: "0px 20px" }}
                        onClick={handleSpecialUserAdd}
                        className="nutgain-primary-button mt-3"
                      >
                        Write
                      </Button>
                    </Panel>
                  ) : (
                    <Panel header={item.name} key="3">
                      {item.inputs ? (
                        <>{buildInputRows(item.name, item.inputs)}</>
                      ) : (
                        <></>
                      )}

                      {isFunctionCallLoading[item.name] ? (
                        <div className="result-container mt-3">
                          <Spin size="small" />
                        </div>
                      ) : (
                        <div className="result-container mt-3">
                          {parse(
                            resultView[item.name] ? resultView[item.name] : ""
                          )}
                        </div>
                      )}

                      <Button
                        size="small"
                        loading={isFunctionCallLoading[item.name]}
                        style={{ padding: "0px 20px" }}
                        onClick={handleQuery(item)}
                        className="nutgain-primary-button mt-3"
                      >
                        Write
                      </Button>
                    </Panel>
                  )}
                </Collapse>
              </Card>
            ))}
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </div>
  );
}

export default WriteFunctions;
