import React, { useEffect, useState } from 'react'
import { Avatar, Card, Button, Skeleton, Space, notification, message, InputNumber, Popover } from 'antd'
import './TrendingNFTItem.css'
import BnbLogo from '../../images/bnb-logo-1.png'
import PlaceholderImg from '../../images/placeholder-img.svg'
import { useAccount } from 'wagmi'
import { prepareWriteContract, writeContract, readContract, waitForTransaction } from '@wagmi/core'
import { nftConfig } from '../../blockchain/nft.config'
import { EditFilled, CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons'
import { formatEther, formatUnits, parseEther } from "viem";
import axios from 'axios'
import jazzicon from '@metamask/jazzicon';
import { useNavigate } from "react-router-dom";
function TrendingNFTItem(props) {

    const navigate = useNavigate();
    const pinata_id = process.env.REACT_APP_PINATA_ID
    const { cardWidth, nftDataId, type, sourcePage, childDataReciver } = props
    const { Meta } = Card;
    const [isLoading, setIsLoading] = useState(false)

    const { address, isConnected } = useAccount()
    const [nftData, setNftData] = useState({})
    const [nftImageUri, setnftImageUri] = useState('')
    // const [isNftdataLoading, setIsNftDataLoading] = useState(true)
    const [isNftImageLoading, setIsNftImageLoading] = useState(true)

    const [price, setPrice] = useState(0.1)
    const [isPriceEditig, setIsPriceEditing] = useState(false)
    const [feePercentage, setFeePercentage] = useState(0)

    const [isNftApprovedForSale, setIsNftApprovedForSale] = useState(false)
    const [isNftApproveLoading, setIsNftApproveLoading] = useState(false)
    const [isNftReadyForsSaleLoading, setIsNftReadyForsSaleLoading] = useState(false)

    const [isNftPurcahseLoading, setIsNftPurchaseLoading] = useState(false);
    const [isLoadingNftMetaData, setIsLoadingNftMetaData] = useState(false)
    const [nftMetaData, setNftMetaData] = useState({})

    const [isLoadingNftPrice, setIsLoadingNftPrice] = useState(false)
    const [userAvatar, setUserAvatar] = useState('');

    const [isNftListingLoading, setIsNftListingLoading] = useState(false)
    // set NFT image
    useEffect(() => {
        // getNftItemImageUrl()
        getNFTprice(nftDataId)
        getNftJsonData(nftDataId)
    }, [nftDataId])

    const getNftJsonData = async (id) => {
        setIsLoadingNftMetaData(true)
        try {
            let config = {
                method: 'get',
                url: `${process.env.REACT_APP_PINATA_URL}ipfs/${pinata_id}/${id}.json`
            };

            const response = await axios(config);
            setNftMetaData(response?.data);
            getNftItemImageUrl(response?.data?.image);
            setIsLoadingNftMetaData(false)
        } catch (error) {
            console.log('Error fetching NFT metadata', error);
            setIsLoadingNftMetaData(false)
        }
    }

    const generateAvatar = (userAddress) => {
        let walletAddress = '';
        if (userAddress === '0x0000000000000000000000000000000000000000') {
            walletAddress = address
        } else {
            walletAddress = userAddress
        }
        const diameter = 50;
        const avatar = jazzicon(diameter, parseInt(walletAddress.slice(2, 10), 16));
        return avatar;
    };

    const generateJazzicon = (userAddress) => {
        const diameter = 50;
        const seed = parseInt(userAddress.slice(2, 10), 16);
        const jazziconHtml = jazzicon(seed, diameter);
        return jazziconHtml;
    };
    // get NFT pirce
    const getNFTprice = async (id) => {
        setIsLoadingNftPrice(false)
        try {
            const nftPriceResponse = await readContract({
                address: nftConfig.marketPlaceContractAddress,
                abi: nftConfig.MarketplaceContractABI,
                functionName: 'listingDetails',
                args: [id]
            })

            const formattedFeeRate = formatEther(nftPriceResponse[1]?.toString());
            if (formattedFeeRate === '0') {
                setPrice('0.1');
            } else {
                setPrice(formattedFeeRate);
            }
            const avatar1Url = generateAvatar(nftPriceResponse[0]);

            // setPrice(formattedFeeRate);
            setUserAvatar(avatar1Url);
            setIsLoadingNftPrice(false);

        } catch (e) {
            console.warn('Error getting NFT price: ', e)
            setIsLoadingNftPrice(false)
        }
    }

    // fetch NFT item image URI
    const getNftItemImageUrl = (imageUrl) => {
        if (imageUrl) {
            const nftItemImageUrl = imageUrl.replace('ipfs://', 'https://rugfreecoin.mypinata.cloud/ipfs/')
            setnftImageUri(nftItemImageUrl)
            setIsNftImageLoading(false)
        }
        else {
            setnftImageUri(PlaceholderImg)
            setIsNftImageLoading(false)
        }
    }

    const handleNftListing = async () => {
        setIsNftListingLoading(true)

        try {

            const { hash, wait } = await writeContract({
                address: nftConfig.marketPlaceContractAddress,
                abi: nftConfig.MarketplaceContractABI,
                functionName: 'listNFTForSale',
                args: [nftDataId.toString(), parseEther(price.toString())]
            })

            console.log("Listing Hash: ", hash);
            const result = await wait()
            console.info('Success: ', result)
            childDataReciver('reaload')
            notification['success']({
                message: 'Listed NFT',

            });


            setIsNftListingLoading(false)
            setIsNftApprovedForSale(false)

        } catch (e) {
            console.warn("Error in listing NFT for in marketplace: ", e);
            setIsNftListingLoading(false)
            // setIsNftApprovedForSale(false)
        }
    }

    // handle approval for sale
    const handleApproveForSale = async () => {
        setIsNftApproveLoading(true)
        console.log("Approval : ");
        try {
            // const { request } = await prepareWriteContract({
            //     address: nftConfig.mintingContractAddress,
            //     abi: nftConfig.MintingContractABI,
            //     functionName: "approve",
            //     args: [nftConfig.marketPlaceContractAddress, nftDataId],
            //   });

            //   const { hash } = await writeContract(request);
            //   const result = await waitForTransaction({ hash });
            const { hash, wait } = await writeContract({
                address: nftConfig.mintingContractAddress,
                abi: nftConfig.MintingContractABI,
                functionName: 'approve',
                args: [nftConfig.marketPlaceContractAddress, nftDataId?.toString()]
            })

            console.log("Approval Hash: ", hash);
            const result = await wait()
            console.info('Success: ', result)

            notification['success']({
                message: 'Approved',
                // description: 'Y',
                role: 'status',
            });

            // sendDataToParent('approvel clicked')

            setIsNftApproveLoading(false)
            setIsNftApprovedForSale(true)

        } catch (e) {
            console.warn("Error in approving NFT for sending marketplace: ", e);
            setIsNftApproveLoading(false)
            setIsNftApprovedForSale(false)
        }

    }

    const handleNftPurchase = async () => {

        setIsNftPurchaseLoading(true)

        try {

            const { hash, wait } = await writeContract({
                address: nftConfig.marketPlaceContractAddress,
                abi: nftConfig.MarketplaceContractABI,
                functionName: "buyNFT",
                args: [nftDataId],
                overrides: {
                    value: parseEther(price)
                }
            });

            const result = await wait();
            // sendDataToParent('buy clicked')
            console.info('Success: ', result)

            // childDataReciver('reaload')
            window.location.reload()
            notification['success']({
                message: 'Buy NFT success!',
                // description: 'Y',
                role: 'status',
            });

        } catch (error) {
            console.warn("Error buying NFT: ", error);
            notification['error']({
                message: 'Error!',
                description: error.message,
                role: 'status',
            });
        }
        setIsNftPurchaseLoading(false)

    }

    const handleNftDelist = async () => {

        setIsNftPurchaseLoading(true)

        try {

            const { hash, wait } = await writeContract({
                address: nftConfig.marketPlaceContractAddress,
                abi: nftConfig.MarketplaceContractABI,
                functionName: 'delistNFT',
                args: [nftDataId]
            })

            console.log("DeListing Hash: ", hash);
            const result = await wait()
            console.info('Success: ', result)
            // childDataReciver('reaload')
            notification['success']({
                message: 'DeListed NFT',

            });
            
            // navigate("/listing");
            window.location.reload()
            setIsNftPurchaseLoading(false)

        } catch (e) {
            console.warn("Error in delisting NFT from marketplace: ", e);
            setIsNftPurchaseLoading(false)
            // setIsNftApprovedForSale(false)
        }

    }
    // Handle NFT Price change by User
    const handlePriceChange = (value) => {
        setPrice(value)
    }

    // Hnadle inline price Editing
    const handleInlinePriceEditing = () => {
        // setPrice()
        setIsPriceEditing(true)
    }



    return (
        <Card
            className={`slider-nft-item ${(isNftApproveLoading || isNftPurcahseLoading || isNftApprovedForSale || isNftPurcahseLoading) ? 'processing' : ''}`}
            loading={isLoading}
            style={{ maxWidth: cardWidth }} >
            <div className="card-info-row-1 d-flex flex-row justify-content-between mb-3">
                <div className="middle-col flex-grow-1 d-flex text-center justify-content-center">
                    <div className='item-name'>{nftMetaData?.name}</div>
                </div>
            </div>

            <div className="img-wrapper nft-img-wrapper mb-3">
                {isNftImageLoading ? (
                    <Skeleton.Image style={{ width: '240px', maxWidth: '100%', height: '250px' }} active={true} />
                ) : (
                    <img className='img-fluid rounded nft-img' src={nftImageUri} ></img>
                )}

            </div>
            <div className="card-info-row-2 d-flex flex-row justify-content-between align-items-start">
                <span className='me-1'>
                    NFT Price
                </span>
                <div className='middle-col'>
                    <div className='meta-val price-meta'>
                        {isPriceEditig ? (
                            sourcePage === 'inventoryPageMyNfts' &&
                            (<Space>
                                <InputNumber
                                    step="0.1"
                                    min={0.1}
                                    size="medium"
                                    value={price}
                                    onChange={handlePriceChange}
                                    className="price-edit"
                                    style={{
                                        width: '65px'
                                    }}
                                />
                                <Button size={'small'} shape="circle" type="text" icon={<CheckCircleFilled style={{ color: '#4751dc', fontSize: '20px', marginTop: '1px' }} />} onClick={() => { setPrice(price); setIsPriceEditing(false) }} />
                                <Button size={'small'} shape="circle" type="text" icon={<CloseCircleFilled style={{ color: '#474961', fontSize: '20px', marginTop: '1px' }} />} onClick={() => { setIsPriceEditing(false) }} />
                            </Space>)
                        ) : (
                            <>
                                <span className='me-1' onClick={handleInlinePriceEditing}>{Number(price).toFixed(2)} BNB</span>


                                {sourcePage === 'inventoryPageMyNfts' &&
                                    <Popover className='pops' content={"Click here to edit the price"} trigger="hover" placement="right">
                                        <EditFilled className='edit-icon' onClick={handleInlinePriceEditing} />
                                    </Popover>
                                }
                                {
                                    sourcePage === 'homePage' && <></>
                                }
                            </>
                        )}

                    </div>
                </div>

            </div>
            <div className="card-info-row-3 mt-2">

                {
                    (type === 'sell-card' && isConnected) ?

                        !isNftApprovedForSale ?

                            <Button loading={isNftApproveLoading} className='buynow-btn' type='primary' size='large' onClick={handleApproveForSale} block>Approve For Sell</Button>
                            :
                            <Button loading={isNftListingLoading} className='buynow-btn' type='primary' size='large'
                                onClick={handleNftListing}
                                block>List Now</Button>

                        : ''
                }

                {
                    sourcePage === 'listingPage' &&
                    <Button className='buynow-btn mb-3' type='primary' onClick={handleNftPurchase} size='large' loading={isNftPurcahseLoading} block>Buy Now</Button>
                }

                {
                    sourcePage === 'inventoryPageSellingItems' &&
                    <Button className='buynow-btn' type='primary' size='large' block onClick={handleNftDelist} loading={isNftPurcahseLoading}>Remove from market</Button>
                }
                {
                    sourcePage === 'homePage' &&
                    <></>
                }
            </div>
        </Card>
    )
}

export default TrendingNFTItem