import axios from 'axios'

// this is the API used to handle user logins and registrations
const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json', 
    'Authorization': `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`
  }
})

export default api

export const AXIOS = axios