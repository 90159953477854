import { Card, Row, Col, Collapse, Input, Button, Spin } from "antd";
import { useState } from "react";
import { getWeb3PRovider } from "../../Services/util.service";
import { ethers } from "ethers";

function ReadFunctions(props) {
  const { readFunctions, contractABI, contractAddress } = props;
  const [inputArguments, setInputArguments] = useState([]);
  const [isFunctionCallLoading, setIsFunctionCallLoading] = useState([]);
  const [resultView, setResultView] = useState([]);
  const { Panel } = Collapse;

  const handleQuery = (abiData) => async () => {
    //decode input values
    const functionName = abiData.name;
    const argumentLength = abiData.inputs.length;
    const argumentArray = [];
    for (let i = 0; i < argumentLength; i++) {
      const key = functionName + i;
      const filteredInput = inputArguments[key];
      argumentArray.push(filteredInput);
    }
    setIsFunctionCallLoading((prev) => ({ ...prev, [functionName]: true }));

    try {
      //create web3 instance
      const provider = getWeb3PRovider();
      const contractInstance = new ethers.Contract(
        contractAddress,
        contractABI,
        provider
      );
      const readOutput = await contractInstance[functionName](...argumentArray);
      const outputArray = [];
      if (readOutput && readOutput.length > 0) {
        for (let i = 0; i < readOutput.length; i++) {
          const readableValue = readOutput[i].toString();
          outputArray.push(readableValue);
        }
      }
      if (outputArray.length > 0) {
        setResultView((prev) => ({ ...prev, [functionName]: outputArray }));
      } else {
        const outputReadable = readOutput.toString();
        setResultView((prev) => ({ ...prev, [functionName]: outputReadable }));
      }
      setIsFunctionCallLoading((prev) => ({ ...prev, [functionName]: false }));
    } catch (error) {
      setResultView((prev) => ({ ...prev, [functionName]: "error" }));
      setIsFunctionCallLoading((prev) => ({ ...prev, [functionName]: false }));
    }
  };

  const handleChange = (e) => {
    const { value, id } = e.target;
    setInputArguments((prev) => ({ ...prev, [id]: value }));
  };

  const buildInputRows = (itemName, inputArgs) => {
    const inputArray = [];
    for (let i = 0; i < inputArgs.length; i++) {
      inputArray.push(
        <div className="mt-1">
          <span key={i}>{inputArgs[i].name}</span>
          <Input
            placeholder={inputArgs[i].type}
            onChange={handleChange}
            key={itemName + i}
            id={itemName + i}
          />
        </div>
      );
    }

    return inputArray;
  };
  return (
    <div>
      {readFunctions.length > 0 ? (
        <Row>
          <Col span={24}>
            {readFunctions?.map((item, index) => (
              <Card className=" mt-3" key={index}>
                <Collapse defaultActiveKey={["1"]}>
                  <Panel header={item.name} key="3">
                    {item.inputs ? (
                      <>{buildInputRows(item.name, item.inputs)}</>
                    ) : (
                      <></>
                    )}

                    {isFunctionCallLoading[item.name] ? (
                      <div className="result-container mt-3">
                        <Spin size="small" />
                      </div>
                    ) : (
                      <div className="result-container mt-3">
                        Output : {resultView[item.name]}
                      </div>
                    )}

                    <Button
                      size="small"
                      loading={isFunctionCallLoading[item.name]}
                      style={{ padding: "0px 20px" }}
                      onClick={handleQuery(item)}
                      className="nutgain-primary-button mt-3"
                    >
                      Query
                    </Button>
                  </Panel>
                </Collapse>
              </Card>
            ))}
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ReadFunctions;
