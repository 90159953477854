import React from 'react'
import twitter_icon from '../../images/twitter-icon.svg'
import email_icon from '../../images/email-icon.svg'
import telegram_icon from '../../images/telegram-icon.svg'

function Socialicons() {
    return (
        <ul className='list-inline social-icons'>
            <li className='list-inline-item'>
                <a target='_blank' href="https://t.me/rugfreecoin">
                    <img src={telegram_icon} />
                </a>
            </li>
            <li className='list-inline-item'>
                <a target='_blank' href="https://twitter.com/rugfreecoins">
                    <img src={twitter_icon} />
                </a>
            </li>
            <li className='list-inline-item'>
                <a href="mailto:contact@rugfreecoins.com">
                    <img src={email_icon} />
                </a>
            </li>
        </ul>
    )
}

export default Socialicons