// slices/dataSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../Services/Api';

// initial state
const initialState= {
    data: null,
    loading: false,
    error: null,
  }


// Create an async thunk for fetching data
export const getNftsForSaleByOwnerResponse = createAsyncThunk('getNftsForSaleByOwner', async ({walletAddress, pageNumber}) => {
  try {
    const response = await api.get(`marketplace/${walletAddress}?page=${pageNumber}&limit=10`);

    console.log('getNftsForSaleByOwner', response)
    return response?.data;
  } catch (error) {
    throw error;
  }
});

// Create a data slice with reducers for success and error cases
const getNftsForSaleByOwnerSlice = createSlice({
  name: 'getAllNftsForSaleByOwner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNftsForSaleByOwnerResponse.pending, (state) => {
        state.nftsForSaleByOwnerDataLoading = true;
        state.nftsForSaleByOwnerDataError = null;
      })
      .addCase(getNftsForSaleByOwnerResponse.fulfilled, (state, action) => {
        state.nftsForSaleByOwnerDataLoading = false;
        state.nftsForSaleByOwnerData = action.payload;

        // console.log('state.data', state.nftsForSaleByOwnerData)
      })
      .addCase(getNftsForSaleByOwnerResponse.rejected, (state, action) => {
        state.nftsForSaleByOwnerDataLoading = false;
        state.nftsForSaleByOwnerDataError = action.error.message;
      });
  },
});

export default getNftsForSaleByOwnerSlice.reducer;
