// slices/dataSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../Services/Api';

// initial state
const initialState= {
    data: null,
    loading: false,
    error: null,
  }


// Create an async thunk for fetching data
export const getAllNftsByOwnerResponse = createAsyncThunk('getAllNftsByOwner', async ({ walletAddress, pageNumber }) => {
  try {
    const response = await api.get(`burn-nft/${walletAddress}?page=${pageNumber}&limit=12`);

    console.log('getAllNftsByOwnerRes: ', response)
    return response.data;
  } catch (error) {
    throw error;
  }
});

// Create a data slice with reducers for success and error cases
const getNftsByOwnerSlice = createSlice({
  name: 'getNftsByOwner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllNftsByOwnerResponse.pending, (state) => {
        state.getNftsByOwnerDataLoading = true;
        state.getNftsByOwnerDataError = null;
      })
      .addCase(getAllNftsByOwnerResponse.fulfilled, (state, action) => {
        state.getNftsByOwnerDataLoading = false;
        state.getNftsByOwnerData = action.payload;

        // console.log('state.data', state.data)
      })
      .addCase(getAllNftsByOwnerResponse.rejected, (state, action) => {
        state.getNftsByOwnerDataLoading = false;
        state.getNftsByOwnerDataError = action.error.message;
      });
  },
});

export default getNftsByOwnerSlice.reducer;
